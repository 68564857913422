<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on">
          <v-icon left> mdi-qrcode-scan </v-icon>
          отримати qr-код
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Налаштування QR-коду</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="initialize">
              <v-icon large> mdi-autorenew </v-icon>
            </v-btn>
            <v-btn dark text @click="print">
              <v-icon large> mdi-printer-outline </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list subheader>
                <v-subheader>Основні</v-subheader>
                <v-list-item>
                  <v-list-item-action>
                    <v-text-field v-model="options.title" outlined dense hide-details></v-text-field>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Опис</v-list-item-title>
                    <v-list-item-subtitle>Текст над qr-кодом</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-text-field v-model="options.qrCodeSize" type="number" outlined dense hide-details></v-text-field>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Розмір</v-list-item-title>
                    <v-list-item-subtitle>QR-code</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-text-field v-model="options.fontSize" type="number" outlined dense hide-details></v-text-field>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Розмір</v-list-item-title>
                    <v-list-item-subtitle>Шрифт</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-select
                      v-model="options.level"
                      :items="qrCorrectionLevel"
                      style="width: 219px"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Level</v-list-item-title>
                    <v-list-item-subtitle>QR-code Error correction level</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-menu
                      v-model="menuBackground"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="options.background"
                          label="Background color"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        />
                      </template>
                      <v-color-picker v-model="options.background"></v-color-picker>
                    </v-menu>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div :style="{ backgroundColor: options.background }" class="color-swatch"></div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-menu
                      v-model="menuForeground"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="options.foreground"
                          label="Foreground color"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        />
                      </template>
                      <v-color-picker v-model="options.foreground"></v-color-picker>
                    </v-menu>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <div :style="{ backgroundColor: options.foreground }" class="color-swatch"></div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-sheet class="d-inline-block pa-4 mt-6" elevation="8">
                <div v-if="!!qrLink" id="printMe" class="d-inline-block text-center">
                  <div :style="{ fontSize: `${options.fontSize}px` }" class="font-weight-medium mb-3">
                    {{ options.title }}
                  </div>
                  <qrcode-vue
                    :value="qrLink"
                    :size="options.qrCodeSize"
                    :level="options.level"
                    :background="options.background"
                    :foreground="options.foreground"
                    render-as="svg"
                  />
                  <div
                    v-if="qrCodeData.address && qrCodeData.address.street && qrCodeData.address.building_number"
                    :style="{ fontSize: `${options.fontSize}px` }"
                    class="font-weight-medium mt-2"
                  >
                    {{ qrCodeData.address.street }}, {{ qrCodeData.address.building_number }}
                  </div>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'QrCode',
  components: { QrcodeVue },

  props: {
    qrLink: {
      type: String,
      required: true,
      default: '',
    },
    qrCodeData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    dialog: false,
    options: {
      title: '',
      qrCodeSize: null,
      fontSize: null,
      level: '',
      background: '',
      foreground: '',
    },
    qrCorrectionLevel: ['L', 'M', 'Q', 'H'],
    menuBackground: false,
    menuForeground: false,
  }),

  mounted() {
    this.initialize()
  },

  methods: {
    initialize() {
      if (this.qrCodeData.name) this.options.title = this.qrCodeData.name
      this.options.qrCodeSize = 180
      this.options.fontSize = 16
      this.options.level = 'H'
      this.options.background = '#FFFFFF'
      this.options.foreground = '#000000'
    },
    async print() {
      await this.$htmlToPaper('printMe')
      this.dialog = false
      this.initialize()
    },
  },
}
</script>

<style scoped lang="scss">
.color-swatch {
  width: 45px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
